<template>
  <div class="animated fadeIn">
    <div class="container mb-5">
      <div class="card bg-white p-5 shadow mt-6">
        <div class="card-body">
          <div class="py-3 text-center">
            <h1 class="mb-5">{{ $t('message.tos') }}</h1>
          </div>
          <div>
            <h4 class="mb-4">{{ $t('pages.tos.title_1') }}</h4>
              <span v-html="$t('pages.tos.content_1')"></span>
          </div>
          <div>
            <h4 class="mb-4">{{ $t('pages.tos.title_2') }}</h4>
            <span v-html="$t('pages.tos.content_2')"></span>
          </div>
          <div>
            <h4 class="mb-4">{{ $t('pages.tos.title_3') }}</h4>
            <span v-html="$t('pages.tos.content_3')"></span>
          </div>
          <div>
            <h4 class="mb-4">{{ $t('pages.tos.title_4') }}</h4>
            <span v-html="$t('pages.tos.content_4')"></span>
          </div>
          <div>
            <h4 class="mb-4">{{ $t('pages.tos.title_5') }}</h4>
            <span v-html="$t('pages.tos.content_5')"></span>
          </div>
          <div>
            <h4 class="mb-4">{{ $t('pages.tos.title_6') }}</h4>
            <span v-html="$t('pages.tos.content_6')"></span>
          </div>
          <div>
            <h4 class="mb-4">{{ $t('pages.tos.title_7') }}</h4>
            <span v-html="$t('pages.tos.content_7')"></span>
          </div>
          <div>
            <h4 class="mb-4">{{ $t('pages.tos.title_8') }}</h4>
            <span v-html="$t('pages.tos.content_8')"></span>
          </div>
          <div>
            <h4 class="mb-4">{{ $t('pages.tos.title_9') }}</h4>
            <span v-html="$t('pages.tos.content_9')"></span>
          </div>
          <div>
            <h4 class="mb-4">{{ $t('pages.tos.title_10') }}</h4>
            <span v-html="$t('pages.tos.content_10')"></span>
          </div>
          <div>
            <h4 class="mb-4">{{ $t('pages.tos.title_11') }}</h4>
            <span v-html="$t('pages.tos.content_11')"></span>
          </div>
          <div>
            <h4 class="mb-4">{{ $t('pages.tos.title_12') }}</h4>
            <span v-html="$t('pages.tos.content_12')"></span>
          </div>
          <div>
            <h4 class="mb-4">{{ $t('pages.tos.title_13') }}</h4>
            <span v-html="$t('pages.tos.content_13')"></span>
          </div>
          <div>
            <h4 class="mb-4">{{ $t('pages.tos.title_14') }}</h4>
            <span v-html="$t('pages.tos.content_14')"></span>
          </div>
          <div>
            <h4 class="mb-4">{{ $t('pages.tos.title_15') }}</h4>
            <span v-html="$t('pages.tos.content_15')"></span>
          </div>
          <div>
            <h4 class="mb-4">{{ $t('pages.tos.title_16') }}</h4>
            <span v-html="$t('pages.tos.content_16')"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "Tos"
    }
</script>

<style scoped>

</style>
